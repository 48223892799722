require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
import Typed from 'typed.js';
import '../stylesheets/application'

import 'bootstrap';
require('bootstrap-select/js/bootstrap-select')
$.fn.selectpicker.Constructor.BootstrapVersion = "4"

document.addEventListener("turbolinks:load", () => {
  feather.replace();

  let phone_input = document.querySelector("#telephone")

  if(phone_input){
    window.intlTelInput(phone_input, {
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
      separateDialCode: true,
      nationalMode: false,
      hiddenInput: "company_data@telephone"
    });
  }
  
  $(".datepicker, input[name='job_infos@duration_of_employment']").datepicker({format: 'dd/mm/yyyy', startView: 2});
  $(".selectpicker").selectpicker();
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="tooltip"]').on('click', function () { $(this).tooltip('hide') })

  $("#comment_message").each(function () {
    this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
  }).on("input", function () {
    this.style.height = 0;
    this.style.height = (this.scrollHeight) + "px";
  });

  $("#comment_message").on('keydown', function(event) {
    if (event.keyCode == 13){
      if (!event.shiftKey){
        event.preventDefault();
        $('#new_comment').submit();        
      } 
    }        
  });

  $("#cc-helper button").click(function(){
    $("#email_email_cc").val( $("#email_email_cc").val() + ( $("#email_email_cc").val()  == "" ?"":", ") + $(this).data("mail"));
  });

  $("input[name='user_application[user_id]']").change(function(){
    if($(this).val() == "invite"){
      $("#user_application_invite_container").show()
    }else{
      $("#user_application_invite_container").hide()
    }
  });

  $("input[name='criminal_check']").change(function(){
    
    if($(this).val() == "yes"){
      $("#section-previous_criminal_convictions").show()
    }else{
      $("#section-previous_criminal_convictions").hide()
    }
  });

  if($("input[name='criminal_check']").length > 0){
    let value = $("#section-previous_criminal_convictions input").map(function(){
      return $(this).val();
    }).get().join('');

    if (value != ""){
      $("#section-previous_criminal_convictions").show()
      $("#criminal-check-yes").attr( "checked", true );
    }else{
      $("#section-previous_criminal_convictions").hide()
    }
  } 

  $("input[name='user_application[has_shares]']").change(function(){
    if($(this).val() == 1){
      $("#user_application_low_shares_wrapper").show();
    }else{
      $("#user_application_low_shares_wrapper").hide();
      $("#user_application_low_shares_1").prop('checked', true);
    }
  });

  $("#relevant_cases li").click(function(){
    var case_id = $(this).data("case-id");
    console.log(case_id)
    $("#inbound_mail_case_id").val(case_id);
    $("form.edit_inbound_mail").submit();
  });

  function calculate_file_size(){ 
    let total_size = 0;

    let selected_files = $(".file_selector_checkbox:checked")

    for (let i = 0; i < selected_files.length; i++) {
      total_size += parseInt($(selected_files[i]).data("file-size"));
    }

    console.log(total_size)

    let size_in_mb = parseInt(total_size) / 1000000;

    console.log(size_in_mb)

    $("#total_mb").text(size_in_mb.toFixed(2) + " MB");
    $("#total_size_indicator").css("width", ((size_in_mb / 10) *100) + "%");
  }


  if($(".file_selector_checkbox").length > 0){
    $(".file_selector_checkbox").change(calculate_file_size);
    calculate_file_size()
  } 


  function language_skills_to_txt(){
    let language_skills = $(".language-skill");
    let language_skills_txt = "";

    for (let i = 0; i < language_skills.length; i++) {
      let lang = $("select", language_skills[i])[0].value;
      let level = $("select", language_skills[i])[1].value;

      if(lang != "" && level != ""){
        language_skills_txt += lang + " (" + level + "), ";
      }
    }

    console.log(language_skills_txt)
    $("#user_application_language_skills").val(language_skills_txt);
  }


  function generate_language_skills_dropdown(){
    let language_skills_wrapper = $("#user_application_language_skills").parent();

    let select_lang = $("<select>").addClass("form-select form-control");
    select_lang.append($("<option>").text("Please Select"));
    select_lang.append($("<option>").text("Deutsch"));
    select_lang.append($("<option>").text("Englisch"));
    select_lang.append($("<option>").text("Französisch"));
    select_lang.append($("<option>").text("Spanisch"));
    select_lang.append($("<option>").text("Bosnisch/Kroatisch/Serbisch"));
    select_lang.change(function(){
      language_skills_to_txt();
    });

    let select_level = $("<select>").addClass("form-select form-control ml-2");
    select_level.append($("<option>").text("Please Select"));
    select_level.append($("<option>").text("A1"));
    select_level.append($("<option>").text("A2"));
    select_level.append($("<option>").text("B1"));
    select_level.change(function(){
      language_skills_to_txt();
    });

    let modify_button = $("<i>").text("+").click(function(){  
      generate_language_skills_dropdown();
      language_skills_to_txt();
    });

    if($(".language-skill").length > 0){
      modify_button = $("<i>").text("-").click(function(){  
        $(this).parent().parent().remove();
      });
      language_skills_to_txt();
    }

    let add_or_remove = $("<div>").addClass("add-or-remove p-2 cursor-pointer").append(modify_button);

    let language_skill = $("<div>").addClass("language-skill d-flex").append(select_lang).append(select_level).append(add_or_remove);

    language_skills_wrapper.append(language_skill);
  }


  if($("#user_application_language_skills").length > 0){
    $("#user_application_language_skills").hide();
    generate_language_skills_dropdown();   
  } 

  if($("#assign_file_case_select").length > 0){
    $("#assign_file_case_select").change(function(){
      $.get("/get_user_requests_for_case", {case_id: $("#assign_file_case_select").val()}, function(data){
        console.log(data);

        for (let i = 0; i < data.length; i++) {
          $("#asign_file_user_request_select").append($("<option>").val(data[i].id).text(data[i].title));
        }
      });
    });
  }
  

  function check_rwr(){
    if($("#user_application_application_type").val() == "RWR Card"){
      $(".rwr-only").show();
    }else{
      $(".rwr-only").hide();
    }
  }
  
  function check_expert(){
    if($("select[name='user_application[application_type]']").val() == "Aufenthaltsgenehmigung für Fachkräfte"){      
      $(".experts-only").show();
    }else{
      $(".experts-only").hide();
    }
  }

  $("select[name='user_application[country_of_employment]']").change(function(){
    $(".at-only").hide();

    if($(this).val() == "Germany"){
      $("select[name='user_application[application_type]']").find('option').remove()
      $("select[name='user_application[application_type]']").append('<option value="No, we need guidance on this point">No, we need guidance on this point</option>')
      $("select[name='user_application[application_type]']").append('<option value="Blue Card">Blue Card</option>')
      $("select[name='user_application[application_type]']").append('<option value="Daueraufenthalt EU">Daueraufenthalt EU</option>')
      $("select[name='user_application[application_type]']").append('<option value="Aufenthaltsgenehmigung für Fachkräfte">Aufenthaltsgenehmigung für Fachkräfte</option>')
    }else if($(this).val() == "Austria"){
      $(".at-only").show();
      $("select[name='user_application[application_type]']").find('option').remove()
      $("select[name='user_application[application_type]']").append('<option value="No, we need guidance on this point">No, we need guidance on this point</option>')
      $("select[name='user_application[application_type]']").append('<option value="RWR Card">RWR Card</option>')
      $("select[name='user_application[application_type]']").append('<option value="Blue Card">Blue Card</option>')
    }

    check_rwr();
    check_expert();
  });

  $("select[name='user_application[application_type]']").change(function(){
    check_expert();
  });

  $("#user_application_application_type").change(function(){
    check_rwr();
  });

  
  if($("#request-pdf").length > 0){
    if($(".feather-x").length > 0){
      $("#request-pdf").addClass('disabled');
    }
  }   

  $(".generated-form input, .generated-form textarea").change(function(){
    $(".generated-form button[type='submit']").removeAttr('disabled');
  })

  $(".generated-form input, .generated-form textarea").keydown(function(){
    $(".generated-form button[type='submit']").removeAttr('disabled');
  })

  check_kids_amount();
  $("input[name='childs@kids_amount']").change(function(){
    check_kids_amount();
  });


  if ($(".js-type-anim").length > 0){
    var options = {
      strings: ['EU work permit.', 'EU citizenship.', 'Blue Card.', 'RWR card.'],
      typeSpeed: 40,
      loop: true
    };

    var typed = new Typed('.js-type-anim', options);
  }

  if($("#tos").length != 0 && $("#tos-error").length != 0 ){
    $("form").submit(function (e) {
      $("#tos-error").hide();
      if (!$("#tos").is(':checked')) {
          e.preventDefault();
          $("#tos-error").show();
          return false;
      }
    });
  }

  if($(".mirror-field").length != 0){
    $(".mirror-field").each(function(){
      let mirror_field = $(this).data("mirror-field");

      console.log(mirror_field)

      $("input[name='"+mirror_field+"']").change(function(){
        $(".mirror-field[data-mirror-field='"+$(this).attr("name")+"']").text($(this).val());
      });
    })
  }

  check_default_or_value();
  $(".default-or-value input[type='radio']").change(function(){ 
    check_default_or_value(); 
  });

  $(".multi-page-form-button").on("click", function(){
    let current_page = $(this).closest(".multi-page-form").data("page");


    let valid_page = true;

    let elements = $("input[required], #organization_vat_id", $(this).closest(".multi-page-form"))

    $(".auto-error").hide();

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].id == "organization_vat_id"){
        var vat_regex = /^\w{2,3}\d{6,9}$/;

        let country = $("#organization_country").val().toLowerCase();

        if(elements[i].value == "" && (country == "austria" || country == "österreich" || country == "at")){
          // do nothing
        }else if(elements[i].value == "" || !vat_regex.test(elements[i].value)){
          $("#vat-id-error").show();
          valid_page = false;
        }
      }else if (elements[i].value == "" || 
        ( elements[i].type == "email" && !isValidEmail(elements[i].value))
      ) {
        console.log(elements[i], "is empty")
        $(elements[i]).after("<div class='auto-error alert alert-danger mb-3 mt-1'><span class='error-en'>Please fill out this field.</span><span class='error-de'>Bitte dieses Feld ausfüllen.</span></div>");
        valid_page = false;
      }
    }


    if(valid_page){
      $(this).hide();
      $(".multi-page-form[data-page='"+(current_page+1)+"']").show();

      $([document.documentElement, document.body]).animate({
          scrollTop: $(".multi-page-form[data-page='"+(current_page+1)+"'] h1").offset().top - 20
      }, 200);
    }
  });

  if($.fn.cloudinary_fileupload !== undefined) {
    $("input.cloudinary-fileupload[type=file]").cloudinary_fileupload();


    $('.cloudinary-fileupload').bind('cloudinarydone', function(e, data) {
      $('.preview').html(
        $.cloudinary.image(data.result.public_id,
          { format: data.result.format, version: data.result.version,
            crop: 'fill', width: 150, height: 100 })
      );

      $(".preview img")[0].src = $(".preview img")[0].src.replace(".pdf",".png");

      $('.image_public_id').val(data.result.public_id);
      $('#file_upload_name').val(data.result.original_filename + "." + data.result.format);
      $(".btn-primary").prop('disabled', false);
      $(".btn-primary").val("Send");//$(".btn-primary").data("disable-with"));
      $(".file-upload-area").text("uploading ...");
      $("#new_file_upload").submit();
      return true;
    });

    $('.cloudinary-fileupload').bind('cloudinarystart', function(e, data) {
      $(".btn-primary").prop('disabled', true);
      $(".btn-primary").val("Uploading ... 0%");
    });

    $('.cloudinary-fileupload').bind('cloudinaryprogress', function(e, data) {
      var progress = Math.round((data.loaded * 100.0) / data.total)

      console.log(progress)

      if(progress != 100){
        $(".btn-primary").prop('disabled', true);
        $(".btn-primary").val("Uploading ... " + progress + "%");
      }
    });
  }
});


function check_default_or_value(){
  $(".default-or-value").each(function(){
    let fill_value = $(this).data("fill-value");

    if($("input[type='radio']:checked", this).length == 0){
      $("input[data-show-input='true']", this).attr( "checked", true );
    }

    if($("input[type='radio']:checked", this).data("show-input") == true){
      $("input[type='text']", this).show()
      if($("input[type='text']", this).val() == fill_value){
        $("input[type='text']", this).val("")
      }      
    }else{
      $("input[type='text']", this).hide()
      $("input[type='text']", this).val(fill_value)
    }
  })
}

function check_kids_amount(){
  let current_val = $("input[name='childs@kids_amount']:checked").val();
  show_kids(4-current_val);
}

function show_kids(amount){
  for (let i = 0; i < 4; i++) {
    $("#section-child"+ i).show();
    $("#hl-child"+ i).show();
  }

  for (let i = 0; i < amount; i++) {
    $("#section-child"+ (4-i)).hide();
    $("#hl-child"+ (4-i)).hide();
  }
}

function isValidEmail(email){
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}